import React, {useEffect, useState} from 'react';
import Counter from '../../components/countdown';
import Button from '../../components/button';

// import blackFriday from '../../images/spring-break/sb-promo.png';

import springBreak from '../../images/spring-break/spring-break.svg';
import bg1 from '../../images/spring-break/bg-1.svg';
import bg2 from '../../images/spring-break/bg-2.svg';
import bundle from '../../images/spring-break/everything-bundle.svg';

import hexometerLogo from '../../images/black-friday-deal/hexometer-logo.svg';
import hexowatchLogo from '../../images/black-friday-deal/hexowatch-logo.svg';
import hexosparkLogo from '../../images/black-friday-deal/hexospark-logo.svg';
import hexomaticLogo from '../../images/black-friday-deal/hexomatic-logo.svg';
import hexofyLogo from '../../images/black-friday-deal/hexofy-logo.svg';

import {simpleCheckIcon} from '../../images/svgIcons/index';

import './styles.scss';
import BadgeSection from '../../components/badge-section';
import Select from '../../components/select';
import {
  getRawCookie,
  removeDocumentReferrer,
  removeImpactClickIdCookie,
  removeImpactMediaPartnerIdCookie,
  setDocumentReferrer,
  setImpactClickIdCookie,
  setImpactMediaPartnerIdCookie,
} from '../../helpers';
import Footer from '../../components/footer';
import Product from '../../components/product';
import {navigate} from 'gatsby';
import Layout from '../../components/layout/layout';

const counts = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
];

const plans = [
  {
    name: 'Tier 1',
    plans: {
      0: {
        price: (
          <div className="bf-pricing-card-price">
            <div className="price">
              <span className="old-price">$1,643/year</span>
              <span className="new-price">$499</span>
            </div>
            <div className="one-time-payment">One time payment</div>
          </div>
        ),
        list: [
          {
            name: (
              <div className="plan-name">
                Silver plan: <span className="bf-line-through">($490/ year)</span>
              </div>
            ),
            description: ['Unlimited web scraping recipes', '4500 Automation credits (monthly quota)'],
            logo: hexomaticLogo,
            linkTo: 'https://hexomatic.com',
            productName: 'Hexomatic',
          },
          {
            name: (
              <div className="plan-name">
                Pro plan: <span className="bf-line-through">($290/ year)</span>
              </div>
            ),
            description: ['Monitor any website for changes', '4500 checks (monthly quota)'],
            logo: hexowatchLogo,
            linkTo: 'https://hexowatch.com',
            productName: 'Hexowatch',
          },
          {
            name: (
              <div className="plan-name">
                Starter plan: <span className="bf-line-through">($108/ year)</span>
              </div>
            ),
            description: ['500 pages / month'],
            logo: hexofyLogo,
            linkTo: 'https://hexofy.com',
            productName: 'Hexofy',
          },
          {
            name: (
              <div className="plan-name">
                1X plan: <span className="bf-line-through">($290/ year)</span>
              </div>
            ),
            description: ['1 email sending account', '1 workspace', 'Unlimited contacts', 'Unlimited emails'],
            logo: hexosparkLogo,
            linkTo: 'https://hexospark.com',
            productName: 'Hexospark',
          },
          {
            name: (
              <div className="plan-name">
                Agency Standard plan: <span className="bf-line-through">($465/ year)</span>
              </div>
            ),
            description: ['Comprehensive website monitoring', '10 monitored websites'],
            logo: hexometerLogo,
            linkTo: 'https://hexometer.com',
            productName: 'Hexometer',
          },
        ],
      },
    },
  },
  {
    name: 'Tier 2',
    plans: {
      0: {
        price: (
          <div className="bf-pricing-card-price">
            <div className="price">
              <span className="old-price">$3,068/year</span>
              <span className="new-price">$899</span>
            </div>
            <div className="one-time-payment">One time payment</div>
          </div>
        ),
        list: [
          {
            name: (
              <div className="plan-name">
                Gold plan: <span className="bf-line-through">($990/ year)</span>
              </div>
            ),
            description: ['Unlimited web scraping recipes', '10,000 Automation credits (monthly quota)'],
            logo: hexomaticLogo,
            linkTo: 'https://hexomatic.com',
            productName: 'Hexomatic',
          },
          {
            name: (
              <div className="plan-name">
                Business plan: <span className="bf-line-through">($550/ year)</span>
              </div>
            ),
            description: ['Monitor any website for changes', '10,000 checks (monthly quota)'],
            logo: hexowatchLogo,
            linkTo: 'https://hexowatch.com',
            productName: 'Hexowatch',
          },
          {
            name: (
              <div className="plan-name">
                Unlimited plan: <span className="bf-line-through">($192/ year)</span>
              </div>
            ),
            description: ['Unlimited pages / month'],
            logo: hexofyLogo,
            linkTo: 'https://hexofy.com',
            productName: 'Hexofy',
          },
          {
            name: (
              <div className="plan-name">
                2X plan: <span className="bf-line-through">($580/ year)</span>
              </div>
            ),
            description: ['2 email sending account', '1 workspace', 'Unlimited contacts', 'Unlimited emails'],
            logo: hexosparkLogo,
            linkTo: 'https://hexospark.com',
            productName: 'Hexospark',
          },
          {
            name: (
              <div className="plan-name">
                Agency Advanced plan: <span className="bf-line-through">($756/ year)</span>
              </div>
            ),
            description: ['Comprehensive website monitoring', '15 monitored websites.'],
            logo: hexometerLogo,
            linkTo: 'https://hexometer.com',
            productName: 'Hexometer',
          },
        ],
      },
      1: {
        price: (
          <div className="bf-pricing-card-price">
            <div className="price">
              <span className="old-price">$6,136/year</span>
              <span className="new-price">$1,798</span>
            </div>

            <div className="one-time-payment">One time payment</div>
          </div>
        ),
        list: [
          {
            name: (
              <div className="plan-name">
                2 Gold plans: <span className="bf-line-through">($1,980/ year)</span>
              </div>
            ),
            description: ['Unlimited web scraping recipes', '20,000 Automation credits (monthly quota)'],
            logo: hexomaticLogo,
            linkTo: 'https://hexomatic.com',
            productName: 'Hexomatic',
          },
          {
            name: (
              <div className="plan-name">
                Business+ 25K plan: <span className="bf-line-through">($1,100/ year)</span>
              </div>
            ),
            description: ['Monitor any website for changes', '25,000 checks (monthly quota)'],
            logo: hexowatchLogo,
            linkTo: 'https://hexowatch.com',
            productName: 'Hexowatch',
          },
          {
            name: (
              <div className="plan-name">
                Team X 3 plan: <span className="bf-line-through">($384 / year)</span>
              </div>
            ),
            description: ['Unlimited pages / month', '2 additional team seats (3 total users)'],
            logo: hexofyLogo,
            linkTo: 'https://hexofy.com',
            productName: 'Hexofy',
          },
          {
            name: (
              <div className="plan-name">
                4X plan: <span className="bf-line-through">($1,160/ year)</span>
              </div>
            ),
            description: ['4 email sending account', '1 workspace', 'Unlimited contacts', 'Unlimited emails'],
            logo: hexosparkLogo,
            linkTo: 'https://hexospark.com',
            productName: 'Hexospark',
          },
          {
            name: (
              <div className="plan-name">
                2 Agency Advanced plans: <span className="bf-line-through">($1,512/ year)</span>
              </div>
            ),
            description: ['Comprehensive website monitoring', '30 monitored websites.'],
            logo: hexometerLogo,
            linkTo: 'https://hexometer.com',
            productName: 'Hexometer',
          },
        ],
      },
      2: {
        price: (
          <div className="bf-pricing-card-price">
            <div className="price">
              <span className="old-price">$9,204/year</span>
              <span className="new-price">$2,697</span>
            </div>

            <div className="one-time-payment">One time payment</div>
          </div>
        ),
        list: [
          {
            name: (
              <div className="plan-name">
                3 Gold plans: <span className="bf-line-through">($2,970/year)</span>
              </div>
            ),
            description: ['Unlimited web scraping recipes', '30,000 Automation credits (monthly quota)'],
            logo: hexomaticLogo,
            linkTo: 'https://hexomatic.com',
            productName: 'Hexomatic',
          },
          {
            name: (
              <div className="plan-name">
                Business+ 50K plan: <span className="bf-line-through">($1,650/year)</span>
              </div>
            ),
            description: ['Monitor any website for changes', '50,000 checks (monthly quota)'],
            logo: hexowatchLogo,
            linkTo: 'https://hexowatch.com',
            productName: 'Hexowatch',
          },
          {
            name: (
              <div className="plan-name">
                Team X 7 plan: <span className="bf-line-through">($576/year)</span>
              </div>
            ),
            description: ['Unlimited pages / month', '6 additional team seats (7 total users)'],

            logo: hexofyLogo,
            linkTo: 'https://hexofy.com',
            productName: 'Hexofy',
          },
          {
            name: (
              <div className="plan-name">
                6X plan: <span className="bf-line-through">($1,740/year)</span>
              </div>
            ),
            description: [
              '6 email sending account',
              '2 workspaces',
              '1+4 - Team seats (5 total users)',
              'Unlimited contacts',
              'Unlimited emails',
            ],
            logo: hexosparkLogo,
            linkTo: 'https://hexospark.com',
            productName: 'Hexospark',
          },
          {
            name: (
              <div className="plan-name">
                3 Agency Advanced plans: <span className="bf-line-through">($2,268/year)</span>
              </div>
            ),
            description: ['Comprehensive website monitoring', '45 monitored websites.'],
            logo: hexometerLogo,
            linkTo: 'https://hexometer.com',
            productName: 'Hexometer',
          },
        ],
      },
    },
  },
];

const BlackFridayPage = () => {
  const [selectedCount, setSelectedCount] = useState({label: '1', value: 1});
  useEffect(() => {
    navigate('/pricing/');
  }, []);
  const handleRedirect = (plan?: string) => {
    window.location.href =
      plan && selectedCount
        ? `https://dash.hexomatic.com/spring-break-promo/?plan=${plan?.toUpperCase().replace(' ', '_') ||
            'TIER_1'}&stack=${selectedCount.value || 1}`
        : 'https://dash.hexomatic.com/spring-break-promo';
  };

  useEffect(() => {
    // Setting document referrer
    const referrer =
      typeof document !== 'undefined' &&
      document &&
      document.referrer &&
      document.referrer.indexOf('https://hexomatic.com') === -1 &&
      document.referrer;
    if (referrer && getRawCookie('document_referrer')) {
      removeDocumentReferrer();
    }
    referrer && setDocumentReferrer(referrer);

    // Setting Impact irclickid in cookies
    const url = window.location.href;
    if (url && url.indexOf('irclickid=') > -1) {
      if (getRawCookie('__irClickId')) {
        removeImpactClickIdCookie();
      }
      const id = url.slice(url.indexOf('irclickid=') + 10);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactClickIdCookie(idFin);
      removeImpactMediaPartnerIdCookie();
    } else if (url && url.toLocaleLowerCase().indexOf('mediapartnerid=') > -1) {
      if (getRawCookie('__mediaPartnerId')) {
        removeImpactMediaPartnerIdCookie();
      }
      const id = url.slice(url.toLocaleLowerCase().indexOf('mediapartnerid=') + 15);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactMediaPartnerIdCookie(idFin);
      removeImpactClickIdCookie();
    }
  }, []);

  // useEffect(() => {
  //   navigate('/pricing/');
  // }, []);

  return (
    <>
      <div className="bf-promo-container">
        <div className="sb-topbar">SAVE 65% OFF</div>

        <section className="bf-section-dark position-relative">
          <img src={bg1} alt="" className="sb-bg-1" />
          <img src={bg2} alt="" className="sb-bg-2" />
          <div className="text-center section-content" style={{zIndex: 10}}>
            <div className="text-center">
              <div className="text-center">
                <img src={springBreak} alt="Spring Break" style={{width: '250px'}} />
              </div>
              <div className="text-center mt-4">
                <img src={bundle} alt="Everything bundle" style={{maxWidth: '100%'}} />
              </div>
            </div>

            <div className="text-center">
              {' '}
              <Counter date="Mar 15 2024 07:00:00 GMT" title="DEAL ENDS IN..." />
              <Button className="my-2" onClick={() => handleRedirect()} secondary>
                GET THE EVERYTHING BUNDLE
              </Button>
            </div>

            <p className="text-center bf-header-p mt-3">
              To celebrate Spring Break we have a very special promotion: Say hello to … the EVERYTHING bundle.
            </p>
            <div className="text-center bf-header-text">
              For a limited time only, save up to <span style={{color: '#FF0000', fontWeight: 600}}>65% OFF</span> our
              entire suite of products and get lifetime access to Hexomatic, Hexowatch, Hexofy, Hexospark, and Hexometer
              for a crazy one-time price.
            </div>
          </div>
        </section>
        <section className="bf-section-light">
          <div className="section-content">
            <h1 className="text-center">Get ready to 10X your business by automating tedious tasks</h1>
            {/* <img src={products} alt="EVERYTHING bundle" className="products-bundle-img d-none d-sm-block" /> */}
            <div className="our-products">
              <div className="left-side"></div>
              <div className="products-card">
                <Product
                  name="Hexomatic"
                  text="Tap into the internet as your own data source and automate 100+ sales, marketing, or research tasks on autopilot."
                  link="https://hexomatic.com"
                  className="prod-1"
                />
                <Product
                  name="Hexowatch"
                  text="Monitor any website for visual, content, source code, technology, availability, or price changes."
                  link="https://hexowatch.com"
                  className="prod-2"
                />
                <Product
                  name="Hexospark"
                  text="Email automation + CRM. Break the ice, personalize emails and automate follow-ups to turn leads into clients."
                  link="https://hexospark.com"
                  className="prod-3"
                />
                <Product
                  name="Hexometer"
                  text="Provides comprehensive peace of mind, monitoring websites 24/7, to catch problems before they affect your business."
                  link="https://hexometer.com"
                  className="prod-4"
                />
                <Product
                  name="Hexofy"
                  text="AI assistant lets you perform GPT tasks on any data you capture or the page you’re browsing with just one click."
                  link="https://hexofy.com"
                  className="prod-5"
                />
              </div>
            </div>
            <div className="text-center">
              {' '}
              <Button className="my-2" onClick={() => handleRedirect()} secondaryDark>
                GET THE EVERYTHING BUNDLE
              </Button>
            </div>
          </div>
        </section>
        <section className="bf-section-light">
          <div className="section-content">
            <h2 className="mb-2">
              What do you get inside the <strong style={{fontWeight: 700}}>EVERYTHING bundle?</strong>
            </h2>
            <h5>
              This is the mother of all deals, providing lifetime access to all our products for a one-time crazy price.
            </h5>
            <div className="pricing-cards-container">
              <div className="row">
                {plans.map((item, index) => (
                  <div className="col-12 col-md-6 py-2">
                    <div className="bf-pricing-card">
                      <div className="bf-pricing-card-name">
                        {item.name}{' '}
                        {/* {index === 1 ? (
                          <Select options={counts} value={selectedCount} onChange={val => setSelectedCount(val)} />
                        ) : null} */}
                      </div>

                      {
                        //@ts-ignore
                        item.plans[index === 0 ? 0 : (selectedCount?.value || 1) - 1].price
                      }
                      <div>
                        <Button outline className="my-2" onClick={() => handleRedirect(item.name)}>
                          BUY NOW
                        </Button>
                      </div>
                      <div className="bf-price-container">
                        <div className="includes">Includes:</div>

                        {//@ts-ignore
                        item.plans[index === 0 ? 0 : (selectedCount?.value || 1) - 1].list.map((sub: any) => (
                          <div className="bf-pricing-card-product">
                            <a href={sub.linkTo} target="_blank">
                              <img src={sub.logo} alt={sub.productName} className="logo" />
                            </a>
                            <div className="product-name">{sub.name}</div>
                            <div>
                              {sub.description.map((listItem: string) => (
                                <div className="plan-description">
                                  {simpleCheckIcon} {listItem}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <p className="pricing-footer-msg mt-3">
              <div className="text-center">
                * Already have some of our products? Click buy now to see your discounted price and take advantage of
                our Spring Break promo to complete the Hexact suite.
              </div>
              <div className="text-center">** All purchases are covered with our 30 day satisfaction guarantee.</div>
            </p>
          </div>
        </section>

        <BadgeSection title={'Get our entire suite of automation platforms used by 130K forward thinking businesses'} />
      </div>
      <Footer pricingPage />
    </>
  );
};

export default BlackFridayPage;
